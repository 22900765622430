<template>
  <transition @after-leave="afterLeave">
    <div
      v-if="!destroyed"
      ref="root"
      v-show="isActive"
      class="ui-notification-popup is-active"
      :class="{
        [`is-size-${size}`]: size,
      }"
      tabindex="-1"
      :role="ariaRole"
      :aria-label="ariaLabel"
      :aria-modal="ariaModal"
    >
      <div class="ui-notification-popup__bg" @click="cancel('outside')" />
      <div ref="content" class="ui-notification-popup__outer">
        <div class="ui-notification-popup__inner" body-scroll-lock-ignore>
          <header v-if="!!$slots.header" class="ui-notification-popup__header">
            <slot name="header" />
          </header>

          <slot :can-cancel="canCancel" :close="close" />
          <button v-if="showX" type="button" class="ui-notification-popup__close" @click="cancel('x')">
            <icon-close />
          </button>

          <footer v-if="!!$slots.actions" class="ui-notification-popup__actions">
            <slot name="actions" />
          </footer>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { computed, defineComponent, nextTick, onBeforeUnmount, onMounted, type Ref, ref, toRefs, watch } from 'vue';
import { type Nullable } from '@/types/generic';
import { scrollLock, scrollUnlock } from '@/utils/scroll';
import IconClose from '@/components/global/elements/IconClose.vue';

const CANCEL_OPTIONS: string[] = ['escape', 'x', 'outside', 'button'];

export default defineComponent({
  name: 'UiNotificationPopup',

  components: {
    IconClose,
  },

  props: {
    modelValue: Boolean,
    canCancel: {
      type: Array,
      default: () => CANCEL_OPTIONS,
    },
    onCancel: {
      type: Function,
      default: null,
    },
    autoFocus: {
      type: Boolean,
      default: true,
    },
    ariaRole: {
      type: String,
      default: '',
      validator: (value: string) => ['', 'dialog', 'alertdialog'].includes(value),
    },
    ariaModal: Boolean,
    ariaLabel: {
      type: String,
      default: '',
    },
    destroyOnHide: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: null,
      validator: (value: string) => ['l', 'm'].includes(value),
    },
  },

  setup(props, context) {
    const { canCancel, modelValue, autoFocus, destroyOnHide } = toRefs(props);

    const root: Ref<Nullable<HTMLElement>> = ref(null);
    const content: Ref<Nullable<HTMLElement>> = ref(null);
    const isActive = ref(modelValue.value || false);
    const destroyed = ref(!modelValue.value);

    const cancelOptions = computed(() => canCancel.value);

    const handleScroll = () => {
      nextTick(() => {
        if (isActive.value && content.value) {
          scrollLock(content.value);
          return;
        }
        scrollUnlock();
      });
    };

    const keyPress = (ev: KeyboardEvent) => {
      if (isActive.value && (ev.key === 'Escape' || ev.key === 'Esc')) {
        cancel('escape');
      }
    };

    const close = () => {
      context.emit('update:modelValue', false);
      context.emit('close');
    };

    const cancel = (method: string) => {
      if (!cancelOptions.value.includes(method)) {
        return;
      }
      context.emit('cancel', arguments);
      if (props.onCancel) {
        props.onCancel.apply(null, arguments);
      }
      close();
    };

    const afterLeave = () => {
      if (destroyOnHide.value) {
        destroyed.value = true;
      }
    };

    watch(modelValue, (val) => {
      isActive.value = val;
    });

    watch(isActive, (val) => {
      if (val) {
        destroyed.value = false;
      }
      handleScroll();

      nextTick(() => {
        if (val && root.value && root.value.focus && autoFocus.value) {
          root.value.focus();
        }
      });
    });

    const showX = computed(() => cancelOptions.value.includes('x'));

    onMounted(() => {
      if (typeof window !== 'undefined') {
        document.addEventListener('keyup', keyPress);
        handleScroll();
      }
    });

    onBeforeUnmount(() => {
      scrollUnlock();
    });

    return {
      root,
      content,

      isActive,
      destroyed,
      cancelOptions,
      showX,

      close,
      cancel,
      afterLeave,
    };
  },
});
</script>

<style lang="scss" scoped>
.ui-notification-popup {
  --popup-max-width: #{rem(450)};
  --popup-max-height: #{rem(600)};
  --popup-height: auto;

  &.is-size-l {
    --popup-max-width: #{rem(800)};
    --popup-height: auto;
    --popup-max-height: #{rem(1000)};
  }

  &.is-size-m {
    --popup-max-width: #{rem(600)};
    --popup-height: auto;
    --popup-max-height: #{rem(600)};
  }

  position: relative;
  z-index: map-get($z-index, popup-bg);

  &__bg {
    @include overlay(0, map-get($z-index, popup-bg), fixed);
    background: rgba(0, 0, 0, 0.6);
  }

  &__outer {
    position: fixed;
    z-index: map-get($z-index, popup);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    overflow: auto;

    padding: rem(20);

    @include mobile {
      padding: var(--container-spacing);
    }
  }

  &__inner {
    position: relative;
    flex: 1;
    height: var(--popup-height);
    max-height: var(--popup-max-height);
    width: auto;
    max-width: var(--popup-max-width);
    margin: auto;
    padding: calc-clamp(320, $desktop, 40, 60) calc-clamp(320, $desktop, 25, 30) calc-clamp(320, $desktop, 25, 30);
    color: var(--primary);
    //border: rem(2) solid var(--primary);
    border-radius: calc-clamp(320, $desktop, 20, 25);
    background: var(--secondary);
    overflow-y: scroll;
  }

  &.has-pad & {
    &__inner {
      padding: rem(40);
    }
  }

  &__close {
    width: rem(35);
    height: rem(35);
    display: block;
    top: calc-clamp(320, $desktop, 15, 20);
    right: calc-clamp(320, $desktop, 15, 20);
    position: absolute;
    padding: rem(10);
    border-radius: 50%;
    @include transition;

    @include hover {
      background-color: var(--primary);
      color: var(--white);

      svg {
        transform: scale(0.7);
      }
    }

    svg {
      width: 100%;
      height: 100%;
      @include transition-slow;
    }
  }

  &__header {
    @include fluid(margin-bottom, 30, 40);
    text-align: center;
  }

  &__actions {
    @include fluid(margin-top, 30, 40);
    text-align: center;
    display: grid;
    grid-auto-flow: column;
    gap: rem(20);
    align-items: center;
    justify-content: center;
  }
}
</style>
